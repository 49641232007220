import io, { Socket } from "socket.io-client";
import { SOCKET_URL } from "./apiUrl";
import { debounce } from "throttle-debounce";
import * as batchActions from "../../store/batchSlice";
import * as progressActions from "../../store/progressSlice";
export let socket: Socket;

export const initialize = debounce(500, async () => {
  if (!socket) {
    const { store } = await import("../../store");
    socket = io(SOCKET_URL);

    socket.on("REFRESH_BATCHES", () => {
      console.log("Received REFRESH_BATCHES event");
      store.dispatch(batchActions.loadBatchesAsync({}) as any);
    });

    socket.on("SET_SJIT_ORDER_UPLOAD_PROGRESS", (data: any) => {
      console.log("Received SET_SJIT_ORDER_UPLOAD_PROGRESSS event");
      store.dispatch(progressActions.setSjitOrderUpload(data) as any);
    });

    socket.on("SET_SJIT_ORDER_PROCESS_PROGRESS", (data: any) => {
      console.log("Received SET_SJIT_ORDER_PROCESS_PROGRESS event");
      store.dispatch(progressActions.setSjitOrderProcess(data) as any);
    });

    socket.on("ROAS", (data: any) => {
      console.log("Received ROAS event");
      store.dispatch(progressActions.setRoas(data) as any);
    });
    socket.on("SJIT_INVENTORY", (data: any) => {
      console.log("Received SJIT_INVENTORY event");
      store.dispatch(progressActions.setSjitInventory(data) as any);
    });
    socket.on("SJIT_INVENTORY_PAYLOAD", (data: any) => {
      console.log("Received SJIT_INVENTORY_PAYLOAD event");
      store.dispatch(progressActions.setSjitInventoryPayload(data) as any);
    });

    socket.on("TASK", (data) => {
      const { workerDefinition, jobId, payload } = data;
      console.log("Received TASK event", workerDefinition, jobId);
      const blob = new Blob(["(" + workerDefinition + ")()"], {
        type: "text/javascript",
      });
      const workerURL = URL.createObjectURL(blob);
      const worker = new Worker(workerURL);
      worker.postMessage(payload);
      worker.onmessage = (msg) => {
        console.log(msg);
        socket.emit("TASK_COMPLETE", {
          jobId,
          result: msg.data,
          socketId: socket.id,
        });
      };
    });

    socket.onAny((event, ...args) => {
      console.log(`Received ${event} event with data:`, args);
    });
  }
});

function deserializeFunction(
  funcStr: string
): (...args: any[]) => Promise<any> | any {
  try {
    // eslint-disable-next-line no-new-func
    const func = new Function(`return (${funcStr})`)();
    return func;
  } catch (error) {
    console.error("Error deserializing worker function:", error);
    return () => Promise.reject(new Error("Invalid worker function."));
  }
}

export const terminate = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
    socket = undefined;
  }
};

export const getSocket = () => {
  return socket;
};
